// @DEPRECATED don't use this function
// @FIXME: don't read from side-effects
export const getPublicPath = (): string => {
    let scriptSrc: string = document.currentScript ? document.currentScript["src"] : getScriptUrlFromTags();
    let [scheme, slash, domain, ...path] = scriptSrc.split("/");
    let [urlLanguage, ...domainParts] = domain.split(".");
    let tempLanguage = (<any>window).CONFIG_LANG || (<any>window).ECSDK_CONFIG_LANG || urlLanguage;
    let language = tempLanguage == "www" ? "en" : tempLanguage;

    return `https://${domain}/${path.slice(0, 3).join("/")}/${language}/`;
};

const getScriptUrlFromTags = (): string => {
    let polyfillScript = document.querySelector("script[id$=\"js_polyfill\"]");
    if (!polyfillScript) {
        let allScripts = document.querySelectorAll("script[src]");
        if (allScripts.length) {
            return  document.querySelectorAll("script[src]")[allScripts.length - 1]["src"];
        }
    }

    if (!polyfillScript) {
        return "";
    }

    return polyfillScript.getAttribute("src");
};
